import { Route, Redirect } from 'react-router-dom'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// <PrivateRoute isAuthenticated = { fnIsLoggedIn } redirect = strURL>
//    <Child props/>
// </PrivateRoute>

export function PrivateRoute({ children, isAuthenticated, redirect, ...rest }) {
    return (
        <Route { ...rest }  render = { ({ location }) =>
            isAuthenticated ? ( children ) :
                ( <Redirect to = { { pathname: redirect, state: { from: location } }} /> )
            }
      />
    );
}