import React    from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import App from './App';
import reportWebVitals from './reportWebVitals';

const BStheme = createTheme({
  palette: {
    secondary: {
      main: '#e4002b',
      dark: '#757575',
    },
    info: {
      main: '#616161',
      contrastText: '#e4002b',
    },
    shape: {
      radius: '50',
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={BStheme}>
      <App />
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
