import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { get } from "idb-keyval";

import { PrivateRoute } from './functions/PrivateRoute'

import CssBaseline    from '@material-ui/core/CssBaseline';

import ErrorBoundary  from './components/ErrorBoundary';
import NotFound       from './components/NotFound';

import Loading        from './components/Loading';

const BSNavbar    = React.lazy(() => import('./components/Navbar'));
const Login       = React.lazy(() => import('./components/Login'));

const Register    = React.lazy(() => import('./components/Register'));
const Forget      = React.lazy(() => import('./components/Forget'));
const Home        = React.lazy(() => import('./components/Home'));

const SucAgen     = React.lazy(() => import('./components/SucAgen'));
const Autorizacion  = React.lazy(() => import('./components/Autorizacion'));
const Baja        = React.lazy(() => import('./components/Baja'));
const Credencial  = React.lazy(() => import('./components/Credencial'));
const Cupon       = React.lazy(() => import('./components/Cupon'));
const Datos       = React.lazy(() => import('./components/Datos'));
const Debito      = React.lazy(() => import('./components/Debito'));
const Formularios = React.lazy(() => import('./components/Formularios'));
const Prestadores = React.lazy(() => import('./components/Prestadores'));
const Urgencias   = React.lazy(() => import('./components/Prestadores/Urgencias'));
const Snackbar    = React.lazy(() => import('@material-ui/core/Snackbar'));

const AppRoutes = [
  {
    path: '/',
    exact: true,
    child: Home,
  },
  {
    path: '/credencial',
    child: Credencial,
  },
  {
    path: '/cupon',
    child: Cupon,
  },
  {
    path: '/formularios',
    child: Formularios,
  },
  {
    path: '/prestadores',
    child: Prestadores,
  },
  {
    path: '/agencias',
    child: SucAgen,
  },
  {
    path: '/urgencias',
    child: Urgencias,
  },
];

export default function App() {
  const [ isLoggedIn,  setIsLoggedIn ]  = useState(false);
  const [ isLoading,   setIsLoading  ]  = useState(false);

  const [ showAlert,   setShowAlert  ]  = useState(false);
  const [ alertText,   setAlertText  ]  = useState('');

  const [ profile,     setProfile ]     = useState({});
  const [ afil,        setAfil ]        = useState({});
  const [ titular,     setTitular ]     = useState({});
  const [ gf,          setGF ]          = useState([]);
  const [ motivosBaja, setMotivosBaja ] = useState([]);
  const [ token,       setToken ]       = useState(null);


  const loadingStarts = () => setIsLoading(true);
  const loadingEnded  = () => setIsLoading(false);

  const doLogin = j => {
    if (j.token === null)
      return false;

    setProfile(j.user);
    setGF(j.grupoFamiliar);
    setAfil(j.grupoFamiliar.filter(afi => j.user.benefID === afi.id)[0]);
    setTitular(j.grupoFamiliar.filter(afi => afi.esTitular === true)[0]);
    setMotivosBaja(j.motivosBaja);
    setToken(j.token);

    setIsLoggedIn(true);

    return true;
  }

  const requestLogOut = () => setIsLoggedIn(false);

  const setFaltanCambios = () => {
    let dataafil = afil;
    dataafil.faltanCambios = true;
    setAfil(dataafil);
  }

  const setFaltanDebitos = () => {
    let dataafil = afil;
    dataafil.faltanDebitos = true;
    setAfil(dataafil);
  }

  const setFaltanBajas = () => {
    let dataafil = afil;
    dataafil.faltanBajas = true;
    setAfil(dataafil);
  }

  const toggleReceiveNews = () => {
    let dataafil = afil;
    dataafil.receiveNews = !dataafil.receiveNews;
    setAfil(dataafil);
  }

  const setAlert = alert => {
    setAlertText(alert);
    setShowAlert(true);
  }


  useEffect(() => {
    get("token")
    .then(loginToken => {
      if (loginToken) {
        fetch("https://api.britanicasalud.com.ar/bs/login/token", {
          headers: {
            'Authorization': `Bearer ${loginToken}`,
          },
          method: 'POST',
        })
        .then(res => {
          if (res.status === 200) return res.json();
        })
        .then(res_json => {
          if (res_json && res_json.hasOwnProperty("user")) {
            doLogin(res_json);
          }
        })
        .catch(err => null )
      }
    })
    .catch(err => null);
  }, []);


  return (
      <ErrorBoundary>
        <div className = "App">
          <CssBaseline />
          <BrowserRouter>
              <Suspense fallback = {  <Loading open = { true } /> }>
              <Loading open = {isLoading } />
              <BSNavbar isLoggedIn = { isLoggedIn }
                         doLogout   = { requestLogOut }
                         user       = { profile }
                         afil       = { afil }
                         token      = { token }
                         loadingStarts = { loadingStarts }
                         loadingEnded  = { loadingEnded }
                         setAlert   = { setAlert }
              />
              <Switch>
                <Route path='/login'>
                      <Login loginUser = { doLogin }  isLoggedIn = { isLoggedIn }
                            loadingStarts = { loadingStarts } loadingEnded  = { loadingEnded }
                            setAlert   = { setAlert }
                      />
                  </Route>
                  <Route path='/register'>
                          <Register loadingStarts = { loadingStarts }
                                    loadingEnded  = { loadingEnded }
                                    setAlert      = { setAlert }
                          />
                  </Route>
                  <Route path='/forget'>
                          <Forget loadingStarts = { loadingStarts }
                                  loadingEnded  = { loadingEnded }
                                  setAlert      = { setAlert }
                          />
                  </Route>
                  { AppRoutes.map((route, index) => (
                      <PrivateRoute
                          key             = { index }
                          path            = { route.path }
                          exact           = { route.exact }
                          isAuthenticated = { isLoggedIn }
                          redirect        = '/login'
                          >
                        < route.child
                          gf       = { gf }
                          afil     = { afil }
                          titular  = { titular }
                          token    = { token }
                          doLogout = { requestLogOut }
                          loadingStarts = { loadingStarts }
                          loadingEnded  = { loadingEnded }
                          setAlert      = { setAlert }
                          />
                      </PrivateRoute>
                    ))
                  }
                  <PrivateRoute
                      path            = '/datos-personales'
                      isAuthenticated = { isLoggedIn }
                      redirect        = '/login'
                  >
                    <Datos
                        afil     = { afil }
                        titular  = { titular }
                        token    = { token }
                        doLogout = { requestLogOut }
                        setFaltanCambios  = { setFaltanCambios }
                        toggleReceiveNews = { toggleReceiveNews }
                        loadingStarts = { loadingStarts }
                        loadingEnded  = { loadingEnded }
                        setAlert      = { setAlert }
                    />
                  </PrivateRoute>
                  <PrivateRoute
                      path            = '/baja'
                      isAuthenticated = { isLoggedIn }
                      redirect        = '/login'
                  >
                    <Baja
                      afil     = { afil }
                      titular  = { titular }
                      gf       = { gf }
                      token    = { token }
                      doLogout = { requestLogOut }
                      motivosBaja      = { motivosBaja }
                      setBajaPendiente = { setFaltanBajas }
                      loadingStarts = { loadingStarts }
                      loadingEnded  = { loadingEnded }
                      setAlert      = { setAlert }
                    />
                  </PrivateRoute>
                  <PrivateRoute
                      path            = '/debito'
                      isAuthenticated = { isLoggedIn }
                      redirect        = '/login'
                  >
                    <Debito
                      afil     = { afil }
                      titular  = { titular }
                      gf       = { gf }
                      token    = { token }
                      doLogout = { requestLogOut }
                      setFaltanDebitos = { setFaltanDebitos }
                      loadingStarts = { loadingStarts }
                      loadingEnded  = { loadingEnded }
                      setAlert      = { setAlert }
                    />
                  </PrivateRoute>
                  <PrivateRoute
                      path            = '/autorizacion'
                      isAuthenticated = { isLoggedIn }
                      redirect        = '/login'
                  >
                    <Autorizacion
                      afil     = { afil }
                      titular  = { titular }
                      gf       = { gf }
                      token    = { token }
                      doLogout = { requestLogOut }
                      setFaltanDebitos = { setFaltanDebitos }
                      loadingStarts = { loadingStarts }
                      loadingEnded  = { loadingEnded }
                      setAlert      = { setAlert }
                    />
                  </PrivateRoute>
                <Route> <NotFound /> </Route>
              </Switch>
              <Snackbar anchorOrigin = {{ vertical: 'bottom', horizontal: 'center' }}
                        open = { showAlert }
                        autoHideDuration = {6000}
                        onClose={ () => setShowAlert(false) }
                        ContentProps = {{ 'aria-describedby': 'message-id' }}
                        message = { <span id="message-id"> { alertText } </span> }
              />
              </Suspense>
          </BrowserRouter>
        </div>
      </ErrorBoundary>
    );

}
